:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;





  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  background-color: #242424;
  color: rgba(255, 255, 255, 0.87);
  color-scheme: light dark;
}

div {
  box-sizing: border-box;
}


/* LOADER */

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #13ABC4;
  box-shadow: 30px 0 0 #ff3d00;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #ff3d00;
  box-shadow: 30px 0 0 #13ABC4;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8)
  }

  50% {
    transform: rotate(360deg) scale(1.2)
  }

  100% {
    transform: rotate(720deg) scale(0.8)
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #ff3d00;
  }

  50% {
    box-shadow: 0 0 0 #ff3d00;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #ff3d00;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #13ABC4;
  }

  50% {
    box-shadow: 0 0 0 #13ABC4;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #13ABC4;
    margin-top: 0;
  }
}

.scroll-container{
  overflow-y: hidden !important;

}